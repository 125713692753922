<template>
  <custom-select
    v-bind="$attrs"
    v-on="$listeners"
    :options="employmentTypes"
    :value="value"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      employmentTypes: [
        'Full Time',
        'Part Time',
        'Contract',
      ],
    };
  },
};
</script>
